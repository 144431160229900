
// example!
// {% include messages.html
//     title="Sample Person!!"
//     messages=site.data.test_messages
//   %}
  

.mac-window.imessage-popup {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    margin: 1rem auto; 
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    max-width: 600px; 
    box-sizing: border-box;
  }

.imessage {
    background-color: #fff;
    border: none; 
    border-radius: 0;
    display: flex;
    flex-direction: column;
    font-family: "San Francisco", -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 14px;
    margin: 0; 
    max-width: none;
    padding: 0.5rem 1.5rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  .imessage p {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
  }
  
  .imessage p::before,
  .imessage p::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }
  
  p.from-me {
    align-self: flex-end;
    background-color: #248bf5;
    color: #fff; 
  }
  
  p.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #248bf5;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }
  
  p.from-me::after {
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform:translate(-30px, -2px);
    width: 10px;
  }
  
  p[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
  }
  
  p.from-me ~ p.from-me {
    margin: 0.25rem 0 0;
  }
  
  p.from-me ~ p.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
  }
  
  p.from-me ~ p.from-me:last-child {
    margin-bottom: 0.5rem;
  }
  
  p.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
  }
  
  p.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }
  
  p.from-them::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }
  
  p[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
  }
  
  p[class^="from-"].emoji::before {
    content: none;
  }
  
  .no-tail::before {
    display: none;
  }
  
  .margin-b_none {
    margin-bottom: 0 !important;
  }
  
  .margin-b_one {
    margin-bottom: 1rem !important;
  }
  
  .margin-t_one {
    margin-top: 1rem !important;
  }
  
  @media screen and (max-width: 800px) {
    .imessage {
      margin: 0; 
      max-width: none;
      padding: 0.25rem 0.875rem;
    }
  
    .imessage p {
      margin: 0.5rem 0;
    }
  }
  
  @media screen and (max-width: 640px) {
    .mac-window.imessage-popup {
      margin: 10px auto; // Reduced margin for smaller screens
      max-width: 95%; // Allow it to take up more width on smaller screens
    }

    .imessage {
      padding: 0.25rem 0.875rem;
    }

    // ... rest of the media query styles ...
  }