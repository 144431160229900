@charset "utf-8";

// Define defaults for each variable.

@font-face {
  font-family: 'Andada Pro';
  src: url('/assets/fonts/AndadaPro-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Andada Pro';
  src: url('/assets/fonts/AndadaPro-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: 'Hanken Grotesk';
  src: url('/assets/fonts/HankenGrotesk-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Hanken Grotesk';
  src: url('/assets/fonts/HankenGrotesk-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Mono';
  src: url('/assets/fonts/FiraMono-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Fira Mono';
  src: url('/assets/fonts/FiraMono-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Fira Mono';
  src: url('/assets/fonts/FiraMono-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Font Awesome 6 Free';
  src: url('/assets/fonts/fa-solid-900.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Font Awesome 6 Free';
  src: url('/assets/fonts/fa-regular-400.ttf') format('truetype');
  font-weight: 400;
}

$base-font-family: "Hanken Grotesk", Arial, "Helvetica Neue", Helvetica, sans-serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$prose-font-family: "Andada Pro", Georgia, serif !default;
$prose-font-weight: 400 !default;
$prose-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #100F0F !default;
$background-color: #FFFCF0 !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #B7B5AC !default;
$grey-color-light: #E6E4D9 !default;
$grey-color-dark:  #6F6E69 !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    820px !default;

$on-palm:          600px !default;
$on-laptop:        820px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: math.div($spacing-unit, 2);
//     padding-left: math.div($spacing-unit, 2);
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting",
  "custom",
  "tufte_excerpts",
  "email",
  "messages",
  "fontawesome/fontawesome",
  "fontawesome/regular",
  "fontawesome/solid"
;
