a {
    color: $text-color;
}

.site-title {
    font-size: 16px;
    letter-spacing: 0px;
    color: $text-color;
}

.prose {
    font-family: $prose-font-family;
    font-weight: $prose-font-weight;
    line-height: $prose-line-height;
}

.prose h1, .prose h2, .prose h3, .prose h4, .prose h5, .prose h6 {
    font-family: $base-font-family;
    line-height: $base-line-height;
}

.katex-html {
    display: none;
}

.katex-display {
    margin: 1em 0;
    font-size: 1.2em;
    display: block;
}

.page-link {
    font-size: 16px;
    letter-spacing: 0px;
    color: $text-color;
}

header a {
    text-decoration: none;
}

header a:hover, a:focus {
    text-decoration: underline;
}

.home-article-link {
    text-decoration: none;
}

.home-article-link:hover, .home-article-link:focus {
    text-decoration: underline;
}


.external-link {
    display: inline;
    align-items: center;
    position: relative;
    margin-right: 0.1em;
}

.external-link::after {
    margin-left: 0.4em;
    margin-bottom: 0.4em;
    content: '\f08e';
    font-family: 'Font Awesome 6 Free'; 
    font-weight: 600; 
    font-size: 0.6em;
    text-decoration: none;
    color: $grey-color-dark;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
}

.mailto-link {
    display: inline;
    align-items: center;
    position: relative;
    margin-right: 0.1em;
}

.mailto-link::after {
    margin-left: 0.4em;
    margin-bottom: 0.3em;
    content: '\f0e0';
    font-family: 'Font Awesome 6 Free'; 
    font-weight: 400; 
    font-size: 0.7em;
    text-decoration: none;
    color: $grey-color-dark;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
}

.home-article-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.home-article-section {
    margin-top: 48px;
}

.home-article-recommended {
    margin-right: 6px;
}

.home-article-title {
    flex: 1 1 auto;
    min-width: 50%;
}

.home-article-external-link {
    color: $grey-color-dark;
    margin-left: 0.2em;
    white-space: nowrap;
}

.home-article-divider {
    border: 0;
    height: 1px;
    color: $grey-color-light;
    background-color: $grey-color-light;
    margin: 15px auto;
    width: 85%;
}

.home-article-right {
    color: $grey-color-dark;
    white-space: nowrap;
    flex: 0 0 auto;
    display: flex;
    margin-left: auto;
    text-align: right;
} 

.home-article-date {
    flex: 1;
}

.home-article-status {
    margin-left: 0.8em;
    font-size: 0.8em;
    flex: 1;
    color: $grey-color;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

.article-status {
    margin-left: 0.6em;
    font-size: 12px;
    flex: 1;
    color: $grey-color;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

.home-article-status {
    line-height: 24px;
}

.article-status {
    line-height: 21px;
}

.home-article-status-hover {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 100%;
    right: -1em;
    background-color: #100F0F;
    color: #CECDC3;
    padding: 5px 10px;
    border: 1px solid #282726;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1;
    margin-bottom: 5px;
    font-style: italic;
    transform: translateY(5px);
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
}

.article-status-hover {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: -1em;
    background-color: #100F0F;
    color: #CECDC3;
    padding: 5px 10px;
    border: 1px solid #282726;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1;
    margin-top: 5px;
    font-style: italic;
    transform: translateY(-5px);
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
    white-space: wrap;
}

.home-article-status:hover .home-article-status-hover,
.home-article-status:focus .home-article-status-hover,
.home-article-status:active .home-article-status-hover,
.article-status:hover .article-status-hover,
.article-status:focus .article-status-hover,
.article-status:active .article-status-hover {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.bold {
    font-weight: bold;
}

.post-content ul,ol {
    width: 85%;
}

.dateline {
    color: $grey-color-dark;
}

.list-item-description {
    color: $grey-color-dark;
    margin-bottom: 5px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-40 {
    margin-top: 40px;
}

.mb-40 {
    margin-bottom: 40px;
}

table {
    max-width: 100%;
    overflow-x: auto;
    display: block;
    -webkit-overflow-scrolling: touch;
}


article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
  position: relative;
}

article h1 .anchor-link,
article h2 .anchor-link,
article h3 .anchor-link,
article h4 .anchor-link,
article h5 .anchor-link,
article h6 .anchor-link {
  position: absolute;
  left: -1.5em;
  top: 0;
  transform: translateY(0.6ex);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  text-decoration: none;
}

article h1:hover .anchor-link,
article h2:hover .anchor-link,
article h3:hover .anchor-link,
article h4:hover .anchor-link,
article h5:hover .anchor-link,
article h6:hover .anchor-link {
  opacity: 0.4;
}

article h1 .anchor-link:hover,
article h2 .anchor-link:hover,
article h3 .anchor-link:hover,
article h4 .anchor-link:hover,
article h5 .anchor-link:hover,
article h6 .anchor-link:hover {
  opacity: 0.7;
}

article h1 .anchor-link { font-size: 0.6em; }
article h2 .anchor-link { font-size: 0.7em; }
article h3 .anchor-link,
article h4 .anchor-link,
article h5 .anchor-link,
article h6 .anchor-link { font-size: 0.8em; }

.end-of-article-divider {
    margin: 2rem 0;
    background-color: $grey-color;
    color: $grey-color;
}

.prose hr:not(.end-of-article-divider) {
    border: none;
    height: auto;
    text-align: center;
    margin: 1.2rem auto;
}

hr:not(.end-of-article-divider)::before {
    font-family: 'Font Awesome 6 Free'; 
    font-weight: 400;
    content: '\2a\2a\2a';
    display: inline-block;
    font-size: 0.6rem;
    color: $grey-color-dark;
    letter-spacing: 5em;
    margin-right: -5em;
    padding: 0 0.5em;
}

.toc-header {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5em;
}


.toc li {
    margin-bottom: 0.4em;
}

.toc {
    font-size: 0.9rem;
    font-family: $base-font-family;
    list-style: none;
    margin: 0 0 2em 0;
    padding: 1em 2em;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    display: inline-block;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #F2F0E5;
    min-width: 30%;
}

.toc ul {
    list-style: none;
}

#toc {
    margin: 0;
}

@media screen and (min-width: 768px) {
    .toc {
        white-space: nowrap;
    }

    .toc li {
        margin-bottom: 0;
    }
}


.post-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    font-family: $base-font-family;
  }
  
  .post-navigation .prev-post,
  .post-navigation .next-post {
    flex: 0 1 48%;
    max-width: 48%;
    padding: 1rem;
    border: 1px solid $grey-color-light;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #F2F0E5;
  }
  
  .post-navigation .prev-post a,
  .post-navigation .next-post a {
    display: block;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .post-navigation .prev-post a:hover,
  .post-navigation .next-post a:hover {
    text-decoration: underline;
  }
  
  .post-navigation .next-post,
  .post-navigation .prev-post {
    text-align: center;
  }

  .post-nav-header {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
  }

  .post-nav-arrow {
    margin: 0 0.5rem;
  }
  
  /* Media query for mobile devices */
  @media screen and (max-width: 600px) {
    .post-navigation {
      flex-direction: column;
    }
  
    .post-navigation .prev-post,
    .post-navigation .next-post {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 1rem;
    }
  }
  
    
.vignette-image {
    position: relative;
    display: inline-block;
    max-width: 100%;
}

.vignette-image img,
.article-image img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 1em;
}

.vignette-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: 
    radial-gradient(
        ellipse at center,
        rgba(255, 252, 240, 0) 0%,
        rgba(255, 252, 240, 0) 40%,
        rgba(255, 252, 240, 1) 100%
    ),
    linear-gradient(to right, 
        rgb(255, 252, 240) 0%, 
        rgba(255, 252, 240, 0) 15%, 
        rgba(255, 252, 240, 0) 85%, 
        rgb(255, 252, 240) 100%
    );
}

.rss-link {
    color: $grey-color !important;
    line-height: 24px;
    font-size: 0.9em;
}

.rss-link:hover {
    color: $grey-color-dark !important;
}

.article-reading-time {
    flex-basis: 100%;
}

.substack {
    margin: auto;
    margin-bottom: 2em;
}

@media screen and (min-width: 768px) {
    .substack {
        width: 480px;
    }
}

@media screen and (max-width:767px) {
    iframe[src*="substack"] {
        width: 100% !important;
    }
}

.highlighter {
    position: relative;
    display: inline;  
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }
  
.highlighter::before {
    content: "";
    position: absolute;
    left: -0.2em;
    right: -0.2em;
    top: -0.1em;
    bottom: -0.1em;
    background-color: #3AA99F;
    background-image: 
    repeating-linear-gradient(
        -55deg,
        rgba(255,255,255,0.5),
        rgba(255,255,255,0.5) 1px,
        transparent 4px,
        transparent 4px
    );
    opacity: 0.5;
    z-index: -1;
    transform: rotate(-0.25deg);
    filter: blur(1px);
    clip-path: url(#highlighter-clip);

    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpath d='M0,0 h100 v100 h-100 Z' fill='white' stroke='black' stroke-width='2' stroke-dasharray='2 2' vector-effect='non-scaling-stroke'/%3E%3C/svg%3E");
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpath d='M0,0 h100 v100 h-100 Z' fill='white' stroke='black' stroke-width='2' stroke-dasharray='2 2' vector-effect='non-scaling-stroke'/%3E%3C/svg%3E");
  }
