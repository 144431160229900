// email example
// {% include gmail_email.html 
//     subject="The subject"
//     from_name="Sender name"
//     from_email="sender@example.com"
//     to_name="Andy Bromberg"
//     to_email="andy@andybromberg.com"
//     date="Fri, Sep 13, 7:35 AM (1 day ago)"
//     content="Hey man!
 
//  What do you think of this?
//  Also I'll text you to find a catchup time
 
//  — Sender" %}
 
/* Add this to your main stylesheet */
.mac-window.gmail-popup {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    margin: 20px 0;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
  }
  
  .mac-toolbar {
    background: #f2f2f2;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .mac-buttons {
    display: flex;
    align-items: center;
  }
  
  .mac-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
    position: relative;
    transition: background-color 0.2s ease;
  }
  
  .mac-button.close { background: #ff5f56; }
  .mac-button.minimize { background: #ffbd2e; }
  .mac-button.maximize { background: #27c93f; }

  .mac-buttons:hover .mac-button.close { background: #ff7b6d; }
  .mac-buttons:hover .mac-button.minimize { background: #ffd175; }
  .mac-buttons:hover .mac-button.maximize { background: #59df7f; }
  
  .mac-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    font-size: 8px;
    color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .mac-buttons:hover .mac-button::before {
    opacity: 1;
  }
  
  .mac-button.close::before { content: '\f00d'; }
  .mac-button.minimize::before { content: '\f068'; }
  .mac-button.maximize::before { content: '\f067'; }
  
  .toolbar-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #5f6368;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
  }
  
  .gmail-email {
    padding: 20px;
  }
  
  .email-header {
    margin-bottom: 20px;
  }
  
  .email-subject {
    font-size: 18px;
    font-weight: 400;
    color: #202124;
    margin-bottom: 15px;
  }
  
  .email-metadata {
    font-size: 14px;
    color: #5f6368;
  }
  
  .email-field {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .field-label {
    font-weight: 500;
    margin-right: 8px;
    width: 45px;
    flex-shrink: 0;
  }
  
  .field-value {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #202124;
    flex: 1;
    min-width: 0;
  }
  
  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    margin-right: 8px;
    flex-shrink: 0;
  }
  
  .name-email {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-width: 0;
    flex: 1;
  }
  
  .name {
    font-weight: 500;
    margin-right: 4px;
  }
  
  .email {
    word-break: break-all;
  }

  .email-divider {
    border-top: 1px solid #e0e0e0;
    margin: 15px 0;
  }
  
  
  .email-body {
    font-size: 14px;
    line-height: 1.6;
    color: #202124;
    white-space: normal;
    padding: 0;
    margin: 0;
  }

  .email-body > * {
    margin: 0;
    padding: 0;
  }

  .email-body p {
    margin: 0;
    padding: 0;
  }

  .email-body p + p {
    margin-top: 0.5em;
  }

  .email-body br {
    display: none;
  }

  .email-body br + br {
    display: block;
    content: "";
    margin-top: 0.5em;
  }

  @media (max-width: 480px) {
    .email-field {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      margin-bottom: 10px;
    }

    .field-label {
      width: 45px; // Set a fixed width for all labels
      margin-right: 8px;
      flex-shrink: 0;
    }

    .field-value {
      flex: 1;
      min-width: 0;
    }

    .avatar {
      display: none;
    }

    .name-email {
      flex: 1;
      min-width: 0;
    }

    .name, .email {
      display: inline;
      margin-right: 4px;
    }

    .email {
      word-break: break-all;
    }

    .toolbar-title {
        position: static;
        transform: none;
        margin-left: 20px;
        width: calc(100% - 80px);
        max-width: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0;
    }
  }

.avatar.andy-avatar {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
}
